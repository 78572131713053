import { Flex, Divider, Text } from "@chakra-ui/react";
import { RealTimeLaborTable } from "@/components/RealTimeLaborTable";
import { BudgetActualChart } from "@/components/BudgetActualChart";
import { BudgetActualCard } from "@/components/BudgetActualCard";
import { OvertimeCard } from "@/components/OvertimeCard";
import styles from './styles.module.scss';


export enum TimePeriod {
  LAST_WEEK = "LAST_WEEK",
  THIS_WEEK = "THIS_WEEK",
  LAST_TWO_WEEKS = "LAST_TWO_WEEKS",
  LAST_DAY = "LAST_DAY"
}

export const getTimePeriodText = (period: TimePeriod) => {
  switch (period) {
    case 'LAST_DAY':
      return 'in the previous day';
    case 'LAST_WEEK':
      return 'in the last 7 days';
    case 'LAST_TWO_WEEKS':
      return 'in the last two weeks';
    default:
      return 'since start of week';
  }
};

const RealTimeLaborDashboard: React.FC = () => {

  return (
    <Flex direction="column" align="center" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
      >
        <Flex direction="column" w="50%" mt={6}>
        <Text
            className={styles.cardHeader}
            mx={6}
          >
            Overtime
          </Text>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <OvertimeCard period={TimePeriod.LAST_DAY} />
          </Flex>
          <Flex className={`${styles.card} ${styles.metricCard} ${styles.bottomCard}`}>
            <OvertimeCard period={TimePeriod.LAST_WEEK} />
          </Flex>
        </Flex>
        <Divider orientation="vertical" height="auto" borderColor="gray.200" my={6} alignSelf="stretch" />
        <Flex direction="column" w="50%" mt={6}>
          <Text
            className={styles.cardHeader}
            mx={6}
          >
            Budget vs. actual
          </Text>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <BudgetActualCard period={TimePeriod.LAST_DAY} />
          </Flex>
          <Flex className={`${styles.card} ${styles.metricCard} ${styles.bottomCard}`}>
            <BudgetActualCard period={TimePeriod.LAST_WEEK} />
          </Flex>
        </Flex>
      </Flex>

      <Text
        className={styles.cardHeader}
        alignSelf="flex-start"
        mx={6}
      >
        Daily budget vs. actual {getTimePeriodText(TimePeriod.LAST_TWO_WEEKS)}
      </Text>
      <Flex
        className={styles.card}
        h="400px"
        width="calc(100% - 3rem)"
      >
        <BudgetActualChart />
      </Flex>

      <Text
        className={styles.cardHeader}
        alignSelf="flex-start"
        mx={6}
      >
        Budget and overtime by supervisor {getTimePeriodText(TimePeriod.LAST_WEEK)}
      </Text>
      <Flex
        className={`${styles.card}`}
        flex={1}
        overflowY="hidden"
        display="flex"
        minHeight="200px"
        width="calc(100% - 3rem)"
      >
        <RealTimeLaborTable />
      </Flex>
    </Flex>
  );
};

export default RealTimeLaborDashboard;
