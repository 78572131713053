import DatePicker from 'react-datepicker';
import "./datepicker-styles.css";
import styles from "./styles.module.scss";
import { useTimezone } from '@/contexts/timezone_context';
import { Box } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { addDays } from 'date-fns';

interface IDateTimeRangePickerProps {
  value: [Date | null, Date | null];
  onChange: (dates: [Date | null, Date | null]) => void;
  isNull?: boolean;
  showTime?: boolean;
  showDate?: boolean;
  popperPlacement?: any;
  disabled?: boolean;
  alignment?: "left" | "center" | "right";
  customDateFormat?: string;
  customBorderColor?: string;
  height?: number;
}

const DateTimeRangePicker = (props: IDateTimeRangePickerProps) => {
  const timezoneContext = useTimezone();
  const { 
    value, 
    onChange, 
    showTime = true, 
    showDate = true, 
    popperPlacement = "bottom", 
    disabled = false, 
    alignment = "center", 
    customDateFormat, 
    customBorderColor, 
    isNull = false, 
    height = 36 
  } = props;

  // Convert values to the correct timezone
  const zonedValue: [Date | null, Date | null] = [
    value[0] ? timezoneContext.toZonedTime(value[0]) : null,
    value[1] ? timezoneContext.toZonedTime(value[1]) : null
  ];
  const [pendingDates, setPendingDates] = useState<[Date | null, Date | null]>(zonedValue);

  let dateFormat = "MM/dd/yyyy h:mm aa";

  if (customDateFormat) {
    dateFormat = customDateFormat;
  } else if (showDate && !showTime) {
    dateFormat = "MM/dd/yyyy";
  } else if (!showDate && showTime) {
    dateFormat = "h:mm aa";
  } else if (!showDate && !showTime) {
    throw new Error("DateTimeRangePicker must show either date or time");
  }

  useEffect(() => {
    setPendingDates([value[0] ? timezoneContext.toZonedTime(value[0]) : null, value[1] ? timezoneContext.toZonedTime(value[1]) : null])
  }, [value]);

  const calculateMaxDate = () => {
    if (pendingDates[0]) {
      return addDays(pendingDates[0], 31);
    }
    return null;
  };

  // Handle range selection & apply timezone conversion
  const handleChange = (dates: [Date | null, Date | null]) => {
    setPendingDates(dates);
    if (dates[0] && dates[1]) {
      const zonedDates: [Date, Date] = [
        timezoneContext.fromZonedTime(dates[0]),
        timezoneContext.fromZonedTime(dates[1])
      ];
      onChange(zonedDates);
    }
  };

  return (
    <Box
      sx={{
        '.react-datepicker__input-container input': {
          border: `1px solid ${isNull ? 'var(--chakra-colors-secondary-red)' : (customBorderColor || 'var(--chakra-colors-chakra-border-color)')}`,
          height: `${height}px`
        },
      }}
    >
      <DatePicker
        selected={pendingDates[0]}
        startDate={pendingDates[0]}
        endDate={pendingDates[1]}
        maxDate={calculateMaxDate()}
        selectsRange
        disabled={disabled}
        onChange={handleChange}
        showPopperArrow={false}
        disabledKeyboardNavigation
        showTimeSelect={showTime}
        autoComplete="off"
        dateFormat={isNull ? "-" : dateFormat}
        wrapperClassName={[styles.wrapper, disabled ? styles.disabled : "", styles[alignment]].join(" ")}
        popperPlacement={popperPlacement}
      />
    </Box>
  );
};

export default DateTimeRangePicker;
