import React, {useEffect, useState, useCallback} from "react";
import { modalAction } from "@/utils/modal_action";
import {
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  FormLabel,
  Button,
  useToast,
  FormControl,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Box,
  Center,
  Menu,
  MenuList,
  MenuItem, ButtonGroup, Heading, SliderMark,
} from "@chakra-ui/react";
import { debounce } from "lodash";

import emptyMap from "../../assets/empty_geofence_map.png";
import { useAuth } from "@contexts/auth_context";
import {formatLatLong} from "@/utils/formatting";
import {EmbeddedMap} from "@/components/EmbeddedMap";

const BuildingModal = ({ isOpen, onClose, location, locationAction, handleDelete }) => {
  const [name, setName] = useState("");

  const [locationCode, setLocationCode] = useState("");
  const [geofenceRadius, setGeofenceRadius] = useState(0);
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Google Maps state
  // https://developers-dot-devsite-v2-prod.appspot.com/maps/documentation/javascript/reference/autocomplete-data#AutocompleteSessionToken
  const [sessionToken, setSessionToken] =
      useState<google.maps.places.AutocompleteSessionToken>();

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service
  const [autocompleteService, setAutocompleteService] =
      useState<google.maps.places.AutocompleteService | null>(null);

  // https://developers.google.com/maps/documentation/javascript/reference/places-service
  const [placesService, setPlacesService] =
      useState<google.maps.places.PlacesService | null>(null);

  const [predictionResults, setPredictionResults] = useState<
      Array<google.maps.places.AutocompletePrediction>
  >([]);

  const [address, setAddress] = useState("");

  const authContext = useAuth();
  const toast = useToast();
  const [toLatLng, setLatLng] = useState(formatLatLong("0","0"));
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [places, setPlaces] = useState<google.maps.PlacesLibrary | null>(null);

  const handleMapLoad = (map: google.maps.Map, places: google.maps.PlacesLibrary) => {
    setMap(map);
    setPlaces(places);
  };

  useEffect(() => {
    if (isOpen) {
      setName(location?.name || "");
      setAddress(location?.address || "");
      setLocationCode(location?.locationCode || "");
      setGeofenceRadius(location?.geofenceRadius || 0);
      setLatitude(location?.latitude || "");
      setLongitude(location?.longitude || "");
    }
  }, [isOpen]);

  useEffect(() => {
    if (latitude && longitude) {
      setLatLng(formatLatLong(latitude, longitude));
    }
  },[latitude, longitude]);

  useEffect(() => {
    if (!places || !map) return;

    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [isOpen, map, places]);

  useEffect(() => {
    if (map) {
      map.panTo(toLatLng)
    }
  }, [toLatLng, map]);

  const onPlaceSelect = (placeDetails : google.maps.places.PlaceResult | null) => {
    let geocode = placeDetails?.geometry?.location

    if (geocode) {
      setLatitude(geocode.lat().toString())
      setLongitude(geocode.lng().toString())

      if (geofenceRadius === 0) {
        setGeofenceRadius(100);
      }
    }
  }

  const fetchPredictions = useCallback(
    debounce(async (inputValue: string) => {
        if (!autocompleteService || !inputValue) {
          setPredictionResults([]);
          return;
        }

        const request = {input: inputValue, sessionToken};
        const response = await autocompleteService.getPlacePredictions(request);

        setPredictionResults(response.predictions);
      }, 500),
      [autocompleteService, sessionToken]
  );

  const onAddressChange = useCallback(
      (e) => {
        const value = e.target.value;

        setAddress(value);
        fetchPredictions(value);
      },
      [fetchPredictions]
  );

  const handleSuggestionClick = useCallback(
      (placeId: string) => {
        if (!places) return;

        const detailRequestOptions = {
          placeId,
          fields: ['geometry', 'name', 'formatted_address'],
          sessionToken
        };

        const detailsRequestCallback = (
            placeDetails: google.maps.places.PlaceResult | null
        ) => {
          onPlaceSelect(placeDetails);
          setPredictionResults([]);
          setAddress(placeDetails?.formatted_address ?? '');
          setSessionToken(new places.AutocompleteSessionToken());
        };

        placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
      },
      [onPlaceSelect, places, placesService, sessionToken]
  );

  const handleMarkerDragEnd = (newLat: number, newLng: number) => {
    setLatitude(newLat.toString());
    setLongitude(newLng.toString());
  };

  const handleSubmit = () => {
    const locationParams = {
      name,
      address,
      geofenceRadius,
      locationCode,
      latitude,
      longitude,
    };

    setIsLoading(true);
    authContext
        .authenticatedFetch(
            locationAction === modalAction.UPDATE
                ? `${import.meta.env.VITE_API_SERVER}/locations/${location.id}`
                : `${import.meta.env.VITE_API_SERVER}/locations`,
            {
              method: locationAction === modalAction.UPDATE ? "PUT" : "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify(locationParams),
            }
        )
        .then((response) => response.json())
        .then(() => {
          onClose(true);
        })
        .catch((error) => {
          toast({
            description: error.message,
            position: "bottom",
            status: "error",
            variant: "left-accent",
            duration: 5000,
            isClosable: true,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
  };

  const showMap = () => {
    return latitude !== "" && longitude !== "" && latitude !== "0" && longitude !== "0"
  };

  if (locationAction == modalAction.DELETE) {
    return (
      <Center>
        <Modal isOpen={isOpen} onClose={onClose} size={"lg"} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmation Required</ModalHeader>
            <ModalCloseButton/>
            <ModalBody>
              <Box style={{textAlign: "center"}}>
                Are you sure you want to delete location {location.name}?
              </Box>
            </ModalBody>
            <ModalFooter>
              <ButtonGroup width="100%" spacing='6'>
                <Button flex={1} colorScheme='gray'
                        isLoading={isLoading}
                        onClick={() => onClose()}>
                  Cancel
                </Button>
                <Button
                  flex={1}
                  variant='solid'
                  bg='secondary.red'
                  colorScheme='red'
                  onClick={handleDelete}>
                  Delete
                </Button>
              </ButtonGroup>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Center>)
  }

    const geofenceMinRadius = 100;
    const geofenceMaxRadius = 3200;
    return (
      <Center>
        <Modal isOpen={isOpen} onClose={onClose} size="full">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Image
                width="100px"
                src={"/image_65-removebg-preview.png"}
                mb={6}
                ml={4}
                mt={4}
              />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction={{ base: "column", md: "row" }} gap="4" justifyContent="center" alignItems="flex-start">
                <Box flexBasis={{ base: "100%", md: "50%" }} maxWidth="462px">
                  <Heading size="lg" mb="2em">{locationAction == modalAction.CREATE ? 'Create New Building' : 'Edit building details'}</Heading>
                  <FormControl mb={'1em'}>
                    <FormLabel mb={".25em"}>Building Name</FormLabel>
                    <Input value={name} onChange={(e) => setName(e.target.value)} placeholder="Location Name" />
                  </FormControl>
                  <FormControl mb={'1em'}>
                    <FormLabel mb={".25em"}>External Location Id</FormLabel>
                    <Input
                      value={locationCode}
                      onChange={(e) => setLocationCode(e.target.value)}
                      placeholder="Enter External Location Id"
                    />
                  </FormControl>
                  <FormControl mb={'1em'}>
                    <Box>
                      <FormLabel mb={".25em"}>Address</FormLabel>
                      <Input
                          value={address}
                          onChange={onAddressChange}
                          placeholder="Location Address"
                      />
                        <Box position="relative">
                          <Menu
                            isOpen={predictionResults.length > 0}
                            placement="bottom"
                            onClose={() => setPredictionResults([])}
                          >
                            <MenuList>
                              {predictionResults.map(({place_id, description}) => {
                                return (
                                  <MenuItem
                                    key={place_id}
                                    onClick={() => handleSuggestionClick(place_id)}
                                    >
                                    {description}
                                  </MenuItem>
                                );
                              })}
                            </MenuList>
                          </Menu>
                        </Box>
                    </Box>
                  </FormControl>
                  {latitude && longitude && (
                    <Box>
                      <FormControl mb={'1em'} pr={'1em'} pl={'1em'}>
                        <FormLabel mb={".25em"}>Geofence Radius</FormLabel>
                        <Slider
                            value={geofenceRadius}
                            min={geofenceMinRadius}
                            max={geofenceMaxRadius}
                            step={100}
                            mt="25px"
                            onChange={(value) => setGeofenceRadius(value)}
                        >
                          <SliderMark value={100} color={"secondary.darkGray"} mt="1em">
                            {geofenceMinRadius + 'm'}
                          </SliderMark>
                          <SliderMark value={100} color={"secondary.darkGray"} mt="1em" width="100%" textAlign="right">
                            {geofenceMaxRadius + 'm'}
                          </SliderMark>
                          <SliderMark
                            value={geofenceRadius}
                            textAlign='center'
                            borderWidth="1px"
                            borderColor={'secondary.gray'}
                            mt='-12'
                            ml='-7'
                            w='65px'
                            borderRadius="md"
                          >
                            {geofenceRadius + 'm'}
                          </SliderMark>
                          <SliderTrack height={'16px'} borderRadius="full">
                            <SliderFilledTrack />
                          </SliderTrack>
                          <SliderThumb
                          boxSize={'32px'}
                            borderWidth="6px"
                            borderStyle="solid"
                            borderColor={"primary.500"}
                            borderRadius="full"
                            bg="white" />
                        </Slider>
                      </FormControl>
                      <Box color="secondary.darkGray" mt="2em" mb="2em">
                      You can drag the pin to adjust the map
                      </Box>
                    </Box>
                  )}

                  <Box display="flex" justifyContent="space-between" width="100%" gap="1em">
                    <Button colorScheme="gray" color='red.500' width="48%" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button width="48%" onClick={handleSubmit} isLoading={isLoading}>
                      {locationAction === modalAction.CREATE ? "Create Building" : "Update"}
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{position: 'relative'}}
                  flexBasis={{ base: "100%", md: "50%" }}
                  ml={{ base: "0", md: "4" }}
                  minHeight="722px"
                  overflow="hidden"
                  borderRadius="3xl">
                  {!showMap() && (<Box style={{position: 'absolute', height: '100%', width: '100%'}}>
                    <Image src={emptyMap} alt="Empty map" />
                  </Box>)}
                  <Box style={{position: 'absolute', height: '100%', width: '100%'}} visibility={showMap() ? "visible" : "hidden"}>
                    <EmbeddedMap
                        icon={{
                          url: '/brightgo-marker.svg',
                          scaledSize: { width: 40, height: 40 },
                          anchor: { x: 20, y: 40 },
                        }}
                        showGeofence={true}
                        geofenceRadius={geofenceRadius}
                        allowMarkerDrag={true}
                        latLng={toLatLng}
                        onMapLoad={handleMapLoad}
                        onMarkerDragEnd={handleMarkerDragEnd}
                      />
                  </Box>
                </Box>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </Center>
  );
};

export default BuildingModal;
