import { useState, useEffect, useRef } from "react";
import { Heading, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Select, Icon, FormControl, FormLabel, useToast, border, Tooltip, Input, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { CSVDownload, CSVLink } from "react-csv";
import { FaChevronDown } from "react-icons/fa";

const ExportDropdown = ({
  csvData,
  csvHeaders,
  customExports = [],
  selectedPayPeriod,
  timezoneContext,
  isExportFetchLoading,
  exportDataMap,
  fetchExportData,
  filename,
}) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [isCustomDataReady, setIsCustomDataReady] = useState(null);
  const csvLinkRefs = useRef({});

  useEffect(() => {
    if (isCustomDataReady !== null) {
      csvLinkRefs.current[isCustomDataReady]?.link.click();
      setIsCustomDataReady(null); // Reset for future exports
    }
  }, [isCustomDataReady]);

  const handleExportCustomClick = async (key) => {
    if (fetchExportData) await fetchExportData(key);
    setIsCustomDataReady(key);
  };

  const formatFileName = (prefix) =>
    `${prefix}_${filename}_${timezoneContext.formatDate(
      selectedPayPeriod?.startDate,
      "MMM_d_yyyy"
    )}_${timezoneContext.formatDate(selectedPayPeriod?.endDate, "MMM_d_yyyy")}.csv`;

  return (
    <Menu>
      <MenuButton marginRight={3} as={Button} rightIcon={<FaChevronDown />}>
        Export
      </MenuButton>
      <MenuList>
        {/* Export Data on Page */}
        <MenuItem>
          <CSVLink
            data={csvData}
            headers={csvHeaders}
            filename={formatFileName("brightgo")}
            style={{ textDecoration: "none" }}
          >
            Data on Page
          </CSVLink>
        </MenuItem>

        {/* Dynamic Custom Exports */}
        {customExports.map(({ key, label }) => (
          <MenuItem key={key} onClick={() => handleExportCustomClick(key)}>
            {isExportFetchLoading && isCustomDataReady === key ? (
              <Spinner size="sm" marginRight="8px" />
            ) : (
              <span>{label}</span>
            )}
          </MenuItem>
        ))}

        {/* Hidden CSVLinks for custom exports */}
        {customExports.map(({ key, enclosingCharacter }) => (
          <CSVLink
            key={key}
            data={exportDataMap[key]?.csvData || []}
            headers={exportDataMap[key]?.csvHeaders || []}
            enclosingCharacter={enclosingCharacter !== undefined ? enclosingCharacter : '"'} // `` is falsy.
            filename={formatFileName(key)}
            style={{ display: "none" }}
            ref={(el) => (csvLinkRefs.current[key] = el)}
          />
        ))}
      </MenuList>
    </Menu>
  );
};
export default ExportDropdown;
