import {Map, Marker, useMap, useMapsLibrary} from "@vis.gl/react-google-maps";
import {Circle} from "@/components/EmbeddedMap/circle";
import React, {useCallback, useEffect, useState} from "react";

type EmbeddedMapProps = {
    latLng: {
        lat: number;
        lng: number;
    },
    geofenceRadius: number,
    icon: {
        url: string,
        scaledSize: { width: number, height: number },
        anchor: { x: number, y: number },
    }
    showGeofence?: boolean
    allowMarkerDrag?: boolean
    setRecenterRef?: any
}

const EmbeddedMap = ({latLng, geofenceRadius, icon, showGeofence, allowMarkerDrag = false, onMapLoad, onMarkerDragEnd, setRecenterRef} : EmbeddedMapProps &
    { onMapLoad?: (map: google.maps.Map, places: google.maps.PlacesLibrary) => void} &
    { onMarkerDragEnd?: (latitude: number, longitude: number) => void}) => {

  const map = useMap();
  const places = useMapsLibrary('places');
  const [center, setCenter] = useState(latLng);

  const handleDragEnd = React.useCallback(() => {
    if (map) {
      const mapCenter = map.getCenter();
      if (mapCenter && onMarkerDragEnd) {
        onMarkerDragEnd(mapCenter.lat(), mapCenter.lng());
      }
    }
  }, [map, onMarkerDragEnd]);

  useEffect(() => {
    // Pass a function to recenter the map to the parent
    if (map && setRecenterRef) {
      setRecenterRef(() => {
        map.setCenter(latLng); // Programmatically recenter the map
      });
    }
  }, [map, latLng, setRecenterRef]);

  useEffect(() => {
    if (latLng) {
      setCenter(latLng);
    }
  }, [latLng]);

  useEffect(() => {
    if (map && places && onMapLoad) {
      onMapLoad(map, places);
    }
  }, [map, places, onMapLoad]);

  return (
    <Map
      defaultZoom={15}
      defaultCenter={latLng}
      style={{height: "100%", width: "100%"}}
      mapTypeControl={false}
      streetViewControl={false}
      zoomControl={false}
      scaleControl={false}
      rotateControl={false}
      fullscreenControl={false}
    >
      <Marker
        position={center}
        icon={icon}
        draggable={allowMarkerDrag}
        onDragEnd={(e) => {
          if (onMarkerDragEnd) {
            onMarkerDragEnd(e.latLng.lat(), e.latLng.lng());
          }
        }}
        onDrag={(e) => {
          setCenter(e.latLng);
        }}
      />
      {showGeofence && geofenceRadius > 0 && (<Circle
        center={center}
        radius={geofenceRadius}
        strokeWeight={0}
        fillColor={"#6362F8"}
        fillOpacity={0.35}
      />)}
    </Map>
  );
}

export default EmbeddedMap;
