import useSWR from "swr";
import { domainUrl } from "@/utils/fetch_utils";
import { useEffect, useMemo, useState } from "react";
import { useAuth } from "@contexts/auth_context";
import { FormControl, FormLabel, Select } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useTimezone } from "@/contexts/timezone_context";
import { DateTimePicker } from "@/components/DateTimePicker";
import DateTimeRangePicker from "../DateTimeRangePicker/date_time_range_picker";

export const PayPeriodTypeMappings = {
  weekly: { label: "1-week" },
  biweekly: { label: "2-week" },
  monthly: { label: "4-week" },
  custom: { label: "Custom" },
}
export interface IPayPeriodOption {
  type: string;
  label: string;
  startDate: Date;
  endDate: Date;
}

interface IPayPeriodSelectProps {
  selectedPayPeriod: IPayPeriodOption | null;
  setSelectedPayPeriod: (type: IPayPeriodOption | null) => void;
  payPeriodOptions: Array<IPayPeriodOption>;
  customStartDate: Date;
  customEndDate: Date;
  setCustomStartDate: (newDate: Date) => void;
  setCustomEndDate: (newDate: Date) => void;
}

const PayPeriodSelect = (props: IPayPeriodSelectProps) => {
  const { selectedPayPeriod, setSelectedPayPeriod, payPeriodOptions, customStartDate, customEndDate, setCustomStartDate, setCustomEndDate } = props;
  
  const timezoneContext = useTimezone();
  const [searchParams, setSearchParams] = useSearchParams();

  // Store custom start & end date as a single state object
  const [customPayPeriod, setCustomPayPeriod] = useState<{ startDate: Date; endDate: Date }>({
    startDate: customStartDate,
    endDate: customEndDate,
  });

  const setSelectedPayPeriodQueryParams = (newValue) => {
    const currentParams = new URLSearchParams(searchParams.toString());
    currentParams.set("pay_period", newValue?.type);

    let newSelectedPayPeriod = newValue;
    if (newValue?.type === "custom") {
      currentParams.set("custom_start_date", encodeURIComponent(customPayPeriod.startDate.toISOString()));
      currentParams.set("custom_end_date", encodeURIComponent(customPayPeriod.endDate.toISOString()));
      setSelectedPayPeriod({ type: "custom", label: PayPeriodTypeMappings.custom.label, ...customPayPeriod });
    } else {
      currentParams.delete("custom_start_date");
      currentParams.delete("custom_end_date");
    }

    setSelectedPayPeriod(newSelectedPayPeriod)
    setSearchParams(currentParams);
  }

  useEffect(() => {
    if (selectedPayPeriod?.type === "custom") {
      const currentParams = new URLSearchParams(searchParams.toString());
      currentParams.set("custom_start_date", encodeURIComponent(customPayPeriod.startDate.toISOString()));
      currentParams.set("custom_end_date", encodeURIComponent(customPayPeriod.endDate.toISOString()));
      setSearchParams(currentParams);
      
      // Prevent redundant re-renders by checking if dates actually changed
      if (
        selectedPayPeriod.startDate.getTime() !== customPayPeriod.startDate.getTime() ||
        selectedPayPeriod.endDate.getTime() !== customPayPeriod.endDate.getTime()
      ) {
        setSelectedPayPeriod({ type: "custom", label: PayPeriodTypeMappings.custom.label, ...customPayPeriod });
      }
    }
  }, [customPayPeriod]);

  return (
    <>
      <FormControl mr={2}>
        <FormLabel>Pay Period</FormLabel>
        <Select
          size='md'
          placeholder='Select Pay Period'
          onChange={(event) => setSelectedPayPeriodQueryParams(payPeriodOptions[event.target.value])}
          value={selectedPayPeriod?.type || undefined}>
          {
            Object.keys(payPeriodOptions).map((key) => {
              return <option key={key} value={key}>{payPeriodOptions[key].label}</option>;
            })
          }
        </Select>
      </FormControl>
      {selectedPayPeriod && (
        <FormControl mb={4} mr={2} minWidth="250px">
          <FormLabel>Custom Date Range</FormLabel>
          <DateTimeRangePicker
            value={[selectedPayPeriod?.startDate, selectedPayPeriod?.endDate]}
            onChange={(dates) => {
              setCustomStartDate(dates[0] || customPayPeriod.startDate);
              setCustomEndDate(dates[1] || customPayPeriod.endDate);
              setCustomPayPeriod({ startDate: dates[0] || customPayPeriod.startDate, endDate: dates[1] || customPayPeriod.endDate })
            }}
            showTime={false}
            showDate
            height={40}
            disabled={selectedPayPeriod?.type !== "custom"}
          />
        </FormControl>
      )}
    </>
  );
};

export default PayPeriodSelect;
