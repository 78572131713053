import { useAuth } from "@contexts/auth_context";
import { Heading, Link, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Select, Icon, FormControl, FormLabel, useToast, border, Tooltip, Input, Spinner, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import { ErrorPage } from "@/components/ErrorPage";
import useSWR, { mutate } from "swr";
import styles from "./styles.module.scss";
import { domainUrl } from "@/utils/fetch_utils";
import { useTimezone } from "@contexts/timezone_context";
import { EventFilterTray } from "@/components/EventFilterTray";
import { useEffect, useMemo, useState, forwardRef, useRef } from "react";
import { DateTimePicker } from "@/components/DateTimePicker";
import { ShiftEditModal } from "@/components/ShiftEditModal";
import PayPeriodSelect, { IPayPeriodOption, PayPeriodTypeMappings } from "@/components/PayPeriodSelect/pay_period_select";
import { useSearchParams } from "react-router-dom";
import { formatDuration } from "@/utils/formatting";
import { toFixedWithoutZero } from "@/utils/labor_hour_utils";
import { openShiftPage } from "@/utils/shift_utils";

import { CSVDownload, CSVLink } from "react-csv";
import { ShiftStatus } from "@/components/ShiftStatus";
import { BsArrowUpCircle, BsArrowDownCircle, BsPencil } from "react-icons/bs";
import { StatusMappings } from "@/components/ShiftStatus/shift_status";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { FaExternalLinkAlt, FaChevronDown } from 'react-icons/fa';
import { nextDayValue } from "@/types/shift";
import { convertCalendarEventFromServer } from "@/types/calendarEvent";
import { EventFilter } from "@/components/EventFilter";
import { isValid, parse, subDays } from "date-fns";
import { EmploymentTypeMappings } from "../TimesheetDashboard/timesheet_dashboard";
import { ExportDropdown } from "@/components/ExportDropdown";

const getEncodedEndTime = (timezoneContext, endDate) => (encodeURIComponent(new Date(timezoneContext.parse(endDate).setHours(23, 59, 59, 999)).toISOString()));

const getUrl = (startDate: Date, endDate: Date, timezoneContext: any) => {
  return `${domainUrl}/calendar_events?start_datetime=${encodeURIComponent(startDate.toISOString())}&end_datetime=${getEncodedEndTime(timezoneContext, endDate)}&additional_fields=shift_pay_rate,shift_estimated_gross_pay,shift_break_rule`;
}

const getExportUrl = (startDate: Date, endDate: Date, type: string, timezoneContext: any) => {
  return `${domainUrl}/calendar_events/export?start_datetime=${encodeURIComponent(startDate.toISOString())}&end_datetime=${getEncodedEndTime(timezoneContext, endDate)}&type=${type}`;
}

const COLUMNS = {
  date: { label: "Date", width: "12.5%", key: "scheduledStartDate" },
  assigned_user: { label: "Employee", width: "12.5%", key: "assignedUserName" },
  location: { label: "Building", width: "12.5%", key: "locationName" },
  // pay_rate_type: { label: "Pay Rate Type", width: "10%", key: "payRateType" },
  employment_type: { label: "Employment Type", width: "10%", key: "employmentType" },
  status: { label: "Status", width: "10%", key: "status" },
  clock_in: { label: "Clocked In", width: "10%", key: "clockInTime" },
  clock_out: { label: "Clocked Out", width: "10%", key: "clockOutTime" },
  duration: { label: "Duration", width: "10%", key: "duration" },
  break_hours: { label: "Break", width: "7.5%", key: "breakHours" },
}

const ActualVsBudgetHighlight = ({ value }) => {
  const theme = useTheme();
  if ((value || value === 0) && (value > 1.05 || value < 0.8)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        bg={theme.colors.secondary.red}
        color="white"
        px={2}
        py={0}
        borderRadius="md"
        width="110px"
        alignSelf="start"
      >
        {/* <Box color={theme.colors.secondary.red} bg="white" borderRadius="full" mr="8px">
          { value > 1.0 ? <BsArrowUpCircle size="24px" color={theme.colors.secondary.red} /> : <BsArrowDownCircle size="24px" /> }
        </Box> */}
        <Text fontSize="24px" fontWeight="medium">{`${(value * 100).toFixed(0)}%`}</Text>
      </Box>
    );
  }

  return (
    <Text fontSize="24px" fontWeight="medium">{value ? `${(value * 100).toFixed(0)}%` : "-"}</Text>
  )
}

const ClockInEdit = ({ originalDate, clockInDatetime, onChange, scheduledStartDatetime }) => {
  const theme = useTheme();
  const borderColor = originalDate?.getTime() !== clockInDatetime?.getTime() ? theme.colors.primary[500] : null;

  return (
    <Box width={28}>
      <DateTimePicker
        value={clockInDatetime || scheduledStartDatetime}
        onChange={(date) => date && onChange(new Date(date.setSeconds(0, 0)))} // Remove seconds/milliseconds
        date={originalDate}
        isNull={!clockInDatetime}
        customDateFormat="  h:mm aa       MM/dd/yyyy" // IMPORTANT TO KEEP THIS FORMAT!!! (hacky but need the date so it doesn't constantly get reset to the currentDate)
        customBorderColor={borderColor}
        height={20}
      />
    </Box>
  )
};

const ClockOutEdit = ({ originalDate, clockOutDatetime, clockInDatetime, onChange, scheduledEndDatetime }) => {
  const theme = useTheme();
  const borderColor = originalDate?.getTime() !== clockOutDatetime?.getTime() ? theme.colors.primary[500] : null;
  const nextDay = nextDayValue(clockInDatetime, clockOutDatetime);

  return (
    <Box width={28}>
      <DateTimePicker
        value={clockOutDatetime || scheduledEndDatetime}
        onChange={(date) => {
          return (date && onChange(new Date(date.setSeconds(0, 0))));
        }} // Remove seconds/milliseconds
        date={originalDate}
        customDateFormat="  h:mm aa       MM/dd/yyyy" // IMPORTANT TO KEEP THIS FORMAT!!! (hacky but need the date so it doesn't constantly get reset to the currentDate)
        customBorderColor={borderColor}
        isNull={!clockOutDatetime}
        height={20}
      />
    </Box>
  )
};

const TimesheetCard: React.FC = () => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();
  const toast = useToast();

  const [results, setResults] = useState<{ [key: number]: any }>({});
  const [filteredData, setFilteredData] = useState<Array<any>>([]);

  // Filtering
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [buildingFilteredIds, setBuildingFilteredIds] = useState<Set<string>>(new Set());
  const [employeeFilteredIds, setEmployeeFilteredIds] = useState<Set<string>>(new Set());
  const [statusFilteredIds, setStatusFilteredIds] = useState<Set<string>>(new Set());
  const [employmentTypeFilteredIds, setEmploymentTypeFilteredIds] = useState<Set<string>>(new Set());
  const [isBuildingFilterLoading, setIsBuildingFilterLoading] = useState<boolean>(true);
  const [isEmployeeFilterLoading, setIsEmployeeFilterLoading] = useState<boolean>(true);
  const [isStatusFilterLoading, setIsStatusFilterLoading] = useState<boolean>(true);
  const [isEmploymentTypeFilterLoading, setIsEmploymentTypeFilterLoading] = useState<boolean>(true);

  const [editedRows, setEditedRows] = useState({}); // Store edited values per row
  const [saving, setSaving] = useState(false);
  const [bulkSaveError, setBulkSaveError] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const [locations, setLocations] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [suggestedUsersByLocationId, setSuggestedUsersByLocationId] = useState<{ [key: number]: Array<any> }>({});
  const [selectedPayPeriod, setSelectedPayPeriod] = useState<IPayPeriodOption | null>(null);

  const [exportDataMap, setExportDataMap] = useState({});
  const [isExportFetchLoading, setIsExportFetchLoading] = useState(false);

  const { data, error, isLoading } = useSWR(selectedPayPeriod ? getUrl(selectedPayPeriod.startDate, selectedPayPeriod.endDate, timezoneContext) : null,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
        .then((response) => response.json())
    }
  ) || { data: [], error: null, isLoading: true };

  const fetchCustomExportData = async (key: string) => {
    if (!selectedPayPeriod) {
      toast({
        title: "Error",
        description: "Please select a pay period.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsExportFetchLoading(true);

    try {
      const url = getExportUrl(selectedPayPeriod.startDate, selectedPayPeriod.endDate, key, timezoneContext);
      const response = await authContext.authenticatedFetch(url, { method: "GET" });
      const data = await response.json();

      const columns = data.results.columns || [];
      const currentData = data.results.rows?.map((row) => {
        const formattedRow = {};
        columns.forEach((col) => {
          const key = col.key;
          const snakeCaseKey = key.replace(/_./g, (match) => match[1].toUpperCase());
          formattedRow[key] = row[snakeCaseKey] ?? "";
        });
        return formattedRow;
      }) || [];

      setExportDataMap((prev) => ({
        ...prev,
        [key]: {
          csvData: currentData,
          csvHeaders: columns,
        },
      }));
    } catch (error) {
      console.error(`Failed to fetch export data for ${key}:`, error);
      toast({
        title: "Error",
        description: `Failed to fetch ${key} export data.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsExportFetchLoading(false); // Reset loading state
    }
  };

  const { data: locationsData, error: locationsError, isLoading: locationsIsLoading } = useSWR(
    `${domainUrl}/locations/user_suggestion_by_location`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
        .then((response) => response.json())
    }
  );

  const currentDate = timezoneContext.startOfDay(new Date());
  const [customStartDate, setCustomStartDate] = useState<Date>(
    subDays(currentDate, 7)
  );
  const [customEndDate, setCustomEndDate] = useState<Date>(currentDate);

  useEffect(() => {
    const startDateParam = searchParams.get("custom_start_date");
    const endDateParam = searchParams.get("custom_end_date");
    if (startDateParam) setCustomStartDate(new Date(decodeURIComponent(startDateParam)));
    if (endDateParam) setCustomEndDate(new Date(decodeURIComponent(endDateParam)));
  }, []);

  const { data: payPeriodData, error: payPeriodError, isLoading: payPeriodIsLoading } = useSWR(
    `${domainUrl}/pay_periods`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
        .then((response) => response.json())
    }
  );

  const payPeriodOptions = useMemo(() => {
    let options = { "custom": { type: "custom", label: PayPeriodTypeMappings.custom.label, startDate: customStartDate, endDate: customEndDate } };

    payPeriodData?.payPeriods?.forEach((payPeriod) => {
      const dtStart = timezoneContext.parse(payPeriod.startDatetime);
      const dtEnd = timezoneContext.parse(payPeriod.endDatetime);

      options[payPeriod.payPeriodType] = { type: payPeriod.payPeriodType, label: PayPeriodTypeMappings[payPeriod.payPeriodType]?.label, startDate: dtStart, endDate: dtEnd }
    });
    return options;
  }, [payPeriodData]);

  useEffect(() => {
    const searchParamsForPayPeriod = searchParams.get("pay_period");
    if (searchParamsForPayPeriod) {
      setSelectedPayPeriod(payPeriodOptions[searchParamsForPayPeriod])
    }
  }, [payPeriodOptions]);

  const idSetsFromOtherFilters = (idSetForCurrentFilter: Set<number>) => {
    return [buildingFilteredIds, employeeFilteredIds, statusFilteredIds, employmentTypeFilteredIds].filter((idSet) => {
      return idSet != idSetForCurrentFilter;
    });
  }

  const rerenderFilters = () => {
    // hide all issue before rerendering to avoid flickering.
    setBuildingFilteredIds(new Set());
    setEmployeeFilteredIds(new Set());
    setStatusFilteredIds(new Set());
    setEmploymentTypeFilteredIds(new Set());
    setResetKey(!resetKey);
  }

  const setFilterLoading = () => {
    setIsBuildingFilterLoading(true);
    setIsEmployeeFilterLoading(true);
    setIsStatusFilterLoading(true);
    setIsEmploymentTypeFilterLoading(true);
  }

  // Handle individual row edit
  const handleRowChange = (id, key, newValue) => {
    setEditedRows((prev) => ({
      ...prev,
      [id]: { ...(prev[id] || {}), [key]: newValue },
    }));
  };

  const getDurationInSeconds = (start: Date, end: Date): number => {
    const startTime = start.getTime(); // Get time in milliseconds
    const endTime = end.getTime(); // Get time in milliseconds
    return (endTime - startTime) / 1000;
  };

  const onUpdateShift = async (currentCalendarEvent, updatedShift) => {
    const existingShift = currentCalendarEvent?.shifts[0];

    // Only include clockInTimestamp and clockOutTimestamp if they differ from the original values
    const shiftData = {};
    if (existingShift) {
      if (updatedShift.clockInTimestamp && updatedShift.clockInTimestamp !== existingShift.clockInTimestamp) {
        shiftData.clockInTimestamp = updatedShift.clockInTimestamp;
      }
      if (updatedShift.clockOutTimestamp && updatedShift.clockOutTimestamp !== existingShift.clockOutTimestamp) {
        shiftData.clockOutTimestamp = updatedShift.clockOutTimestamp;
      }
      if (updatedShift.mealStartTimestamp && updatedShift.mealStartTimestamp !== existingShift.mealStartTimestamp) {
        shiftData.mealStartTimestamp = updatedShift.mealStartTimestamp;
      }
      if (updatedShift.mealEndTimestamp && updatedShift.mealEndTimestamp !== existingShift.mealEndTimestamp) {
        shiftData.mealEndTimestamp = updatedShift.mealEndTimestamp;
      }

      if (updatedShift.paidBreak !== null && updatedShift.paidBreak !== existingShift.paidBreak) {
        shiftData.paidBreak = updatedShift.paidBreak;
      }
    }

    // Merge remaining fields of updatedShift into shiftData
    const finalShiftData = existingShift ? { ...existingShift, ...shiftData } : updatedShift;

    if (existingShift) {
      // Update existing shift
      return authContext.authenticatedFetch(
        `${import.meta.env.VITE_API_SERVER}/shifts/${currentCalendarEvent.shifts[0].id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalShiftData),
        }
      );
    } else {
      let response = null;
      if (currentCalendarEvent) {
        // Create new shift if it doesn't exist
        response = await authContext.authenticatedFetch(
          `${import.meta.env.VITE_API_SERVER}/shifts/get_or_create?&assignment_id=${currentCalendarEvent.assignmentId}&calendar_start_datetime=${currentCalendarEvent.scheduledStartDatetime}&assignedUserId=${currentCalendarEvent.assignedUserId}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
      } else {
        const durationInSeconds = getDurationInSeconds(finalShiftData.calendarStartDatetime, finalShiftData.calendarEndDatetime);
        // Create new shift if it doesn't exist
        response = await authContext.authenticatedFetch(
          `${import.meta.env.VITE_API_SERVER}/shifts/get_or_create?&location_id=${finalShiftData.locationId}&supervisor_id=${finalShiftData.supervisorId}&assignedUserId=${finalShiftData.employeeId}&calendar_start_datetime=${finalShiftData.calendarStartDatetime}&duration=${durationInSeconds}`,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
      }

      const result = await response.json();

      // Update the newly created shift
      return authContext.authenticatedFetch(
        `${import.meta.env.VITE_API_SERVER}/shifts/${result.shift.id}`,
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(finalShiftData),
        }
      );
    }
  }

  // const CustomDateInput = forwardRef(({ value, onClick, borderColor, nextDay, isNull }, ref) => (
  //   <Flex
  //     onClick={(event) => {
  //       event.stopPropagation();
  //       onClick(event); // Open date picker
  //     }}
  //     border="1px"
  //     borderColor={borderColor || (value && !isNull ? theme.colors.secondary.gray : theme.colors.secondary.red)}
  //     borderRadius="md"
  //     px={2}
  //     textAlign="center"
  //     cursor="pointer"
  //     flexDirection="row"
  //     alignItems="center"
  //   >
  //     { isNull ? "-" : (value || "-") }
  //     { nextDay ? (
  //       <Tooltip label={`${nextDay} day`} fontSize="md" placement="top" shouldWrapChildren>
  //         <HiOutlineExclamationCircle color="#6362F8" size={14} style={{ marginLeft: "4px" }} />
  //       </Tooltip>
  //     ) : null }
  //   </Flex>
  // ));

  // Handle bulk save
  const handleBulkSave = async () => {
    setSaving(true);
    setBulkSaveError(null);
    const updatedRows = Object.entries(editedRows);

    try {
      await Promise.all(
        updatedRows.map(async ([id, { clockInTimestamp, clockOutTimestamp }]) => {
          const currentCalendarEvent = results[id];

          const updateShiftPromise = await onUpdateShift(currentCalendarEvent, { clockInTimestamp, clockOutTimestamp });
          return updateShiftPromise;
        })
      ).then(() => {
        mutate(getUrl(selectedPayPeriod?.startDate, selectedPayPeriod?.endDate, timezoneContext));
        toast({
          title: "Shifts updated.",
          description: "The shift details have been successfully updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setEditedRows({}); // Clear edits after successful save
      })
    } catch (err) {

      console.log(err);
      toast({
        description: err.message,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    if (data) {
      const resultsHash: any = {};
      data.calendarEvents.forEach((item: any) => {
        const result = convertCalendarEventFromServer(item, data.locations, data.users, timezoneContext);
        resultsHash[result.id] = result;
      });
      setResults(resultsHash);
    } else {
      setResults({});
    }
  }, [data]);

  const resultValues = useMemo(() => Object.values(results), [results]);

  useEffect(() => {
    if (locationsData) {
      setLocations(Object.values(locationsData.locations).sort((a, b) => a.name.localeCompare(b.name)));
      setUsers(Object.keys(locationsData?.users).map((userId) => ({ ...(locationsData?.users[userId]), id: userId })));

      // Structure: { locationId: [User1, User2] }
      const transformedUserSuggestions = Object.keys(locationsData.userSuggestions).reduce((result, key) => {
        const userIds = locationsData.userSuggestions[key]
        result[key] = userIds.map((userId: string | number) => ({ ...(locationsData?.users[userId]), id: userId }));
        return result;
      }, {});
      setSuggestedUsersByLocationId(transformedUserSuggestions);
    } else {
      setLocations([]);
      setUsers([]);
      setSuggestedUsersByLocationId({});
    }
  }, locationsData);

  const calculateShiftDuration = (shift) => {
    if (!shift || !shift.duration) return null;

    const { duration, lunchMinutes, paidBreak } = shift;
    return lunchMinutes && !paidBreak
      ? duration - (lunchMinutes * 60)
      : duration;
  }

  const filterData = (currentData: Array<any>) => {
    const filterConditions = {
      building: (id: any) => buildingFilteredIds.has(id),
      employee: (id: any) => employeeFilteredIds.has(id),
      status: (id: any) => statusFilteredIds.has(id),
      employmentType: (id: any) => employmentTypeFilteredIds.has(id),
    };
    const flags = {
      building: true,
      employee: true,
      status: true,
      employmentType: authContext?.state.features?.employmentTypeIncluded,
    }

    return currentData.filter((result: any) => {
      return Object.entries(flags).every(([key, isActive]) => {
        return !isActive || filterConditions[key]?.(result.id);
      });
    });
  }

  const sortedFilteredData = filterData(Object.values(results)).sort((a, b) => a.scheduledStartDatetime - b.scheduledStartDatetime);
  const groupedData = (sortedFilteredData || []).reduce((acc, item) => {
    const formattedScheduledStartDate = timezoneContext.formatDate(item.scheduledStartDatetime, "MMM d, yyyy");

    if (!acc[formattedScheduledStartDate]) {
      acc[formattedScheduledStartDate] = [];
    }
    acc[formattedScheduledStartDate].push(item);
    return acc;
  }, {})


  // TOPLINE SUMMARY
  const uniqueCleanerIds = [...new Set((sortedFilteredData || []).map((result) => result?.assignedUserId))];
  const uniqueLocationIds = [...new Set((sortedFilteredData || []).map((result) => result?.locationId))];
  const uniqueEventStatuses = [...new Set((sortedFilteredData || []).map((result) => result?.status))];

  const queryParams = new URLSearchParams();

  if (selectedPayPeriod) {
    queryParams.append("start_datetime", encodeURIComponent(selectedPayPeriod.startDate.toISOString()));
    queryParams.append("end_datetime", getEncodedEndTime(timezoneContext, selectedPayPeriod?.endDate));
    queryParams.append("group", "employee_id");
  }

  uniqueCleanerIds.forEach(id => queryParams.append("cleaner_ids[]", id));
  uniqueLocationIds?.forEach(id => queryParams.append("location_ids[]", id));
  uniqueEventStatuses?.forEach(id => queryParams.append("event_statuses[]", id));

  const timesheetSummaryUrl = selectedPayPeriod
    ? `${domainUrl}/calendar_events/timesheet_summary?${queryParams.toString()}`
    : null;
  const { data: toplineData, error: toplineError, isLoading: toplineIsLoading } = useSWR(timesheetSummaryUrl,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
        .then((response) => response.json())
    }
  ) || { data: [], error: null, isLoading: true };

  const getTotalActualHours = () => {
    const totalDurationHours = (toplineData?.results || []).reduce((sum, result) => {
      return sum + (result.actualHours || 0);
    }, 0);

    return toFixedWithoutZero(totalDurationHours);
  }

  const getTotalScheduledHours = () => {
    const totalDurationHours = (toplineData?.results || []).reduce((sum, result) => {
      return sum + (result.scheduledHours || 0);
    }, 0);

    return toFixedWithoutZero(totalDurationHours);
  }

  const getTotalOtHours = () => {
    const totalDurationHours = (toplineData?.results || []).reduce((sum, result) => {
      return sum + (result.otHours || 0);
    }, 0);

    return toFixedWithoutZero(totalDurationHours);
  }
  const topLevelTotals = { actualHours: getTotalActualHours(), scheduledHours: getTotalScheduledHours(), otHours: getTotalOtHours() };

  const csvData = (sortedFilteredData || []).map((result) => ({
    scheduledStartDate: timezoneContext.formatDate(result.scheduledStartDatetime, "MMM d, yyyy") || "-",
    assignedUserName: result.assignedUser?.name || "-",
    locationName: result.location?.name || "-",
    payRateType: result.shifts[0]?.payRate?.rateType || "-",
    employmentType: result.assignedUser?.employmentType || "-",
    status: result.status || "-",
    clockInTime: result.shifts[0]?.clockInTimestamp
      ? timezoneContext.formatDate(result.shifts[0].clockInTimestamp, "h:mm aa")
      : "-",
    clockOutTime: result.shifts[0]?.clockOutTimestamp
      ? timezoneContext.formatDate(result.shifts[0].clockOutTimestamp, "h:mm aa")
      : "-",
    duration: formatDuration(calculateShiftDuration(result.shifts[0])) || "-",
    breakHours: result.shifts[0]?.lunchMinutes ? formatDuration(result.shifts[0]?.lunchMinutes * 60) : "-"
  }));

  const customExports = [];
  if (authContext.state?.features?.hasWinteamExport) {
    customExports.push({ key: "winteam", label: "Winteam" });
  }
  if (authContext.state?.features?.hasBluechipCustomExport) {
    customExports.push({ key: "bluechip_custom_timecard", label: "Bluechip Custom" });
  }

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.calendarEvents) {
      // This applies the filters to the just loaded issues.
      // This will also eventually set the filters to be loaded.
      rerenderFilters();
    } else {
      // Set filters to be loading when data is reset to null.
      setFilterLoading();
    }
  }, [data, results]);


  if (isLoading || saving || payPeriodIsLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error} />;
  }

  // handle API specific errors
  if (data?.error) {
    return <ErrorPage errorMessage={data.error} />;
  }

  return (
    <Flex
      flexDirection="column"
      height="100%"
    >
      <Heading
        as="h1"
        size="lg"
        marginLeft={6}
        marginRight={6}
        paddingTop={6}
        marginBottom={6}
      >
        <Flex flexDirection="row" alignContent="center" justifyContent="space-between">
          <Text>Timesheet Card</Text>
        </Flex>
      </Heading>
      {/* Controls on top */}
      <Flex flexDirection="row" alignContent="center" justifyContent="space-between" marginLeft={6} marginRight={6}>
        <Flex>
          <PayPeriodSelect
            selectedPayPeriod={selectedPayPeriod}
            setSelectedPayPeriod={setSelectedPayPeriod}
            payPeriodOptions={payPeriodOptions}
            customStartDate={customStartDate}
            customEndDate={customEndDate}
            setCustomStartDate={setCustomStartDate}
            setCustomEndDate={setCustomEndDate}
          />
        </Flex>
        <Flex flexDirection="row" align="center" height="75px">
          {
            customExports.length > 0 ? (
              <>
                <ExportDropdown
                  csvData={csvData}
                  csvHeaders={Object.values(COLUMNS)}
                  filename="timesheet_card"
                  selectedPayPeriod={selectedPayPeriod}
                  timezoneContext={timezoneContext}
                  isExportFetchLoading={isExportFetchLoading}
                  fetchExportData={fetchCustomExportData}
                  exportDataMap={exportDataMap}
                  customExports={customExports}
                />
              </>
            ) : (
              <CSVLink
                data={csvData}
                headers={Object.values(COLUMNS)}
                filename={`timesheet_card_${timezoneContext.formatDate(
                  selectedPayPeriod?.startDate,
                  "MMM_d_yyyy"
                )}_${timezoneContext.formatDate(selectedPayPeriod?.endDate, "MMM_d_yyyy")}.csv`}
              >
                <Button marginRight={3} onClick={() => { }}>Export</Button>
              </CSVLink>
            )
          }
          <Button onClick={() => setIsEditModalOpen(true)}>+ Add Shift</Button>
        </Flex>
      </Flex>

      {/* Filters */}
      <Box
        borderRadius="3xl"
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100px"
      >
        <Flex
          justifyContent="space-between"
          mx={6}
          mt={6}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text>Filter by:</Text>
            <Flex
              alignItems={"center"}
            >
              <Box mr={6}>
                <EventFilter
                  key={"BuildingFilter" + resetKey}
                  isLoading={isBuildingFilterLoading}
                  events={Object.values(results)}
                  eventIdSetsFromOtherFilters={idSetsFromOtherFilters(buildingFilteredIds)}
                  getFilterKey={(result) => result.location?.name}
                  getEventId={(result) => result.id}
                  convertFilterKeyToLabel={(filterKey) => filterKey}
                  updateEvent={(ids) => {
                    setBuildingFilteredIds(ids);
                    setIsBuildingFilterLoading(false);
                  }}
                  filterName="Building"
                />
              </Box>
              <Box mr={6}>
                <EventFilter
                  key={"EmployeeFilter" + resetKey}
                  isLoading={isEmployeeFilterLoading}
                  events={Object.values(results)}
                  eventIdSetsFromOtherFilters={idSetsFromOtherFilters(employeeFilteredIds)}
                  getFilterKey={(result) => result.assignedUser?.name}
                  getEventId={(result) => result.id}
                  convertFilterKeyToLabel={(filterKey) => filterKey}
                  updateEvent={(ids) => {
                    setEmployeeFilteredIds(ids);
                    setIsEmployeeFilterLoading(false);
                  }}
                  filterName="Employee"
                />
              </Box>
              {authContext.state?.features?.employmentTypeIncluded ? (
                <Box mr={6}>
                  <EventFilter
                    key={"EmploymentTypeFilter" + resetKey}
                    isLoading={isEmploymentTypeFilterLoading}
                    events={Object.values(results)}
                    eventIdSetsFromOtherFilters={idSetsFromOtherFilters(employmentTypeFilteredIds)}
                    getFilterKey={(result) => result.assignedUser?.employmentType || "-"}
                    getEventId={(result) => result.id}
                    convertFilterKeyToLabel={(filterKey) => EmploymentTypeMappings[filterKey] || filterKey}
                    updateEvent={(ids) => {
                      setEmploymentTypeFilteredIds(ids);
                      setIsEmploymentTypeFilterLoading(false);
                    }}
                    filterName="Employment Type"
                  />
                </Box>
              ) : null}
              <Box mr={6}>
                <EventFilter
                  key={"StatusFilter" + resetKey}
                  isLoading={isStatusFilterLoading}
                  events={Object.values(results)}
                  eventIdSetsFromOtherFilters={idSetsFromOtherFilters(statusFilteredIds)}
                  getFilterKey={(result) => result.status || "-"}
                  getEventId={(result) => result.id}
                  convertFilterKeyToLabel={(filterKey) => StatusMappings[filterKey] || filterKey}
                  updateEvent={(ids) => {
                    setStatusFilteredIds(ids);
                    setIsStatusFilterLoading(false);
                  }}
                  filterName="Status"
                />
              </Box>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setFilterLoading();
                  setSearchParams('');
                  rerenderFilters();
                }}
              >
                Clear all
              </Button>
            </Flex>
          </Flex>
          {/* <EventFilterTray
            data={resultValues}
            filterDefs={[
              // { getFilterKey: ((item: any) => item.payRateType || "-"), name: "Pay Rate Type" },
              { getFilterKey: ((item: any) => item.location?.name), name: "Building" },
              { getFilterKey: ((item: any) => item.assignedUser?.name), name: "Employee" },
              { getFilterKey: ((item: any) => item.status || "-"), name: "Status", convertFilterKeyToLabel: (filterKey) => StatusMappings[filterKey] || filterKey},
            ]}
            setFilteredData={setFilteredData}
          /> */}
        </Flex>
      </Box>

      {/* Table */}
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100%"
      >
        <Flex
          flexDirection="row"
          backgroundColor="secondary.lightGray"
          justifyContent="space-between"
          borderRadius={12}
          marginLeft={6}
          marginRight={6}
          marginTop={6}
          padding="16px"
        >
          {toplineIsLoading ? (
            <div className={styles.loadingState}>
              <Spinner size='xl' color="purple.500" />
            </div>
          ) : (
            <>
              <Flex flexDirection="column" flex={1}>
                <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Actual Hours</Text>
                <Text fontSize="24px" fontWeight="medium">{topLevelTotals.actualHours}</Text>
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Scheduled Hours</Text>
                <Text fontSize="24px" fontWeight="medium">{topLevelTotals.scheduledHours}</Text>
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">Actual vs Scheduled</Text>
                <ActualVsBudgetHighlight value={topLevelTotals.scheduledHours ? ((topLevelTotals.actualHours || 0.0) / topLevelTotals.scheduledHours) : null} />
                {/* <Text fontSize="24px" fontWeight="medium">50</Text> */}
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Text textColor="secondary.darkGray" fontSize="12px" marginBottom="8px">OT Hours</Text>
                <Text fontSize="24px" fontWeight="medium">{topLevelTotals.otHours}</Text>
              </Flex>
            </>
          )}
        </Flex>
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer} flexDirection="column">
            <Flex width="100%" justifyContent="flex-end">
              <Button variant="ghost" width="110px" height="35px" mr={4} onClick={() => setEditedRows({})} disabled={saving || Object.keys(editedRows).length <= 0}>
                Clear Edits
              </Button>
              <Button variant="solid" width="110px" height="35px" onClick={handleBulkSave} disabled={saving || Object.keys(editedRows).length <= 0}>
                {saving ? 'Saving...' : 'Save Edits'}
              </Button>
            </Flex>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white" width="100%">
                <Tr>
                  {
                    Object.values(COLUMNS).map((column, index) => {
                      if (column.key === "employmentType" && !authContext?.state?.features?.employmentTypeIncluded) {
                        return null;
                      }

                      return (
                        <Th
                          key={column.key as string}
                          width={column.width}
                          textColor={"gray.500"}
                          textTransform={"none"}
                          fontWeight={"normal"}
                          fontSize={12}
                          pl={index === 0 ? 6 : 0}
                          pr={0}
                        >
                          {column.label}
                        </Th>

                      )
                    })
                  }
                  <Th width="5%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0}>
                    <Box width="35px"></Box>
                  </Th>
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {Object.keys(groupedData).sort((a, b) => new Date(a) - new Date(b)).map((formattedDate) => (
                  groupedData[formattedDate].sort((a, b) => a.scheduledStartDatetime - b.scheduledStartDatetime).map((result, index) => {
                    const currentEditRowId = result.id;
                    const editedClockInValue = editedRows[currentEditRowId]?.clockInTimestamp;
                    const editedClockOutValue = editedRows[currentEditRowId]?.clockOutTimestamp;
                    const scheduledEndDatetime = new Date(result.scheduledStartDatetime.getTime() + (result.duration * 1000));

                    return (
                      <Tr width="100%"
                        key={result.id}
                      >
                        <Td width="12.5%" pl={6} pr={0}>{index === 0 ? formattedDate : ""}</Td>
                        <Td width="12.5%" pl={0} >{result.assignedUser?.name || "-"}</Td>
                        <Td width="12.5%" pl={0} >{result.location?.name || "-"}</Td>
                        {/* <Td width="15%" pl={0} >{result.shifts[0]?.payRate?.rate || "-"}</Td> */}
                        {/* <Td width="10%" pl={0}>{result.shifts[0]?.payRate?.rateType || "-"}</Td> */}
                        {authContext.state?.features?.employmentTypeIncluded ? <Td width="10%" pl={0}><Text noOfLines={2} wordBreak="break-all">{EmploymentTypeMappings[result.assignedUser?.employmentType] || "-"}</Text></Td> : null}
                        <Td width="10%" pl={0} >
                          <ShiftStatus eventStatus={result.status} />
                        </Td>
                        <Td width="10%" pl={0} >
                          <ClockInEdit
                            originalDate={result.shifts[0]?.clockInTimestamp}
                            clockInDatetime={editedClockInValue || result.shifts[0]?.clockInTimestamp}
                            onChange={(newValue) => handleRowChange(currentEditRowId, "clockInTimestamp", newValue || new Date(result.scheduledStartDatetime))}
                            scheduledStartDatetime={new Date(result.scheduledStartDatetime)}
                          />
                        </Td>
                        <Td width="10%" pl={0} >
                          <ClockOutEdit
                            originalDate={result.shifts[0]?.clockOutTimestamp}
                            clockInDatetime={editedClockInValue || result.shifts[0]?.clockInTimestamp}
                            clockOutDatetime={editedClockOutValue || result.shifts[0]?.clockOutTimestamp}
                            onChange={(newValue) => handleRowChange(currentEditRowId, "clockOutTimestamp", newValue || scheduledEndDatetime)}
                            scheduledEndDatetime={scheduledEndDatetime}
                          />
                        </Td>
                        <Td
                          width="10%"
                          pl={0}
                        // style={{ color: result.shifts[0]?.duration && (result.shifts[0]?.duration < result.duration) || (result.shifts[0]?.duration > result.duration) ? theme.colors.secondary.red : "" }}
                        >
                          {formatDuration(calculateShiftDuration(result.shifts[0]))}
                        </Td>
                        <Td width="7.5%" pl={0} >{result.shifts[0]?.lunchMinutes ? formatDuration(result.shifts[0]?.lunchMinutes * 60) : "-"}</Td>
                        <Td
                          width="5%"
                          pl={0}
                        >
                          <Flex align="center">
                            <Box
                              as="button"
                              p={2}
                              _hover={{ color: theme.colors.primary[500], transform: 'scale(1.1)' }}
                              transition="all 0.2s"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setSelectedEvent(result);
                                setIsEditModalOpen(true);
                              }}
                            >
                              <BsPencil />
                            </Box>
                            <Link
                              href={result.shifts[0] && "/shift/" + result.shifts[0]?.id}
                              onClick={!result.shifts[0] ? () => {
                                openShiftPage(authContext, result);
                              } : undefined}
                              target="_blank">
                              <FaExternalLinkAlt style={{ color: "rgb(26, 32, 44)" }} />
                            </Link>
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })))}
              </Tbody>
            </Table>
          </Flex>
        </Flex >
      </Box >

      <ShiftEditModal
        isOpen={isEditModalOpen}
        initialShift={selectedEvent !== null && selectedEvent !== undefined ? {
          locationId: selectedEvent.locationId,
          supervisorId: selectedEvent.supervisorId,
          employeeId: selectedEvent.assignedUserId,
          ...(selectedEvent.shifts[0] || {
            calendarStartDatetime: selectedEvent.scheduledStartDatetime,
            calendarEndDatetime: new Date(selectedEvent.scheduledStartDatetime.getTime() + (selectedEvent.duration * 1000)),
          }),
        } : null}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedEvent(null);
        }}
        onUpdateShift={async (currentCalendarEvent, paidBreak) => {
          await onUpdateShift(currentCalendarEvent, paidBreak).then(() => mutate(getUrl(selectedPayPeriod?.startDate, selectedPayPeriod?.endDate, timezoneContext)));
        }}
        calendarEvent={selectedEvent}
        users={users}
        locations={locations.reduce((acc, location) => {
          acc[location.id] = location;
          return acc;
        }, {})}
        onSave={() => {
          setIsEditModalOpen(false);
          setSelectedEvent(null);
          mutate(getUrl(selectedPayPeriod?.startDate, selectedPayPeriod?.endDate, timezoneContext));
        }}
        usersByLocationId={suggestedUsersByLocationId}
      />
    </Flex >
  );
};

export default TimesheetCard;
