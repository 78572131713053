import { useState } from 'react'
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  ButtonGroup,
  Input,
  Checkbox,
  Box,
  Text,
  Divider,
  useToast,
  FormLabel,
  FormControl,
  Textarea,
} from '@chakra-ui/react';
import { addSeconds, addDays, formatISO } from "date-fns";
import { useAuth } from "./../../contexts/auth_context";
import { ConfirmationModal } from "@/components/ConfirmationModal";
import styles from "./schedule_edit_modal.module.scss";
import { formatDuration } from '@/utils/formatting';
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { renderUserName } from "@/utils/formatting";
import { DateTimePicker } from '../DateTimePicker';
import { useTimezone } from '@/contexts/timezone_context';
import { CalendarEvent } from '@/types/calendarEvent';
import CalendarEventLabels from '../CalendarEventLabels/calendar_event_labels';
import Select from 'react-select';

const NO_SELECTION_OPTION = '';

enum ConfirmationModalType {
  Edit = "Edit",
  Delete = "Delete",
  Create = "Create",
}

type ScheduleEditEvent = {
  calendarStartDatetime: Date | null;
  assignmentId: number | null;
  calendarDuration: number;
  locationId: number | null;
  supervisorId: number | null;
  employeeId: number | null;
  previousEmployeeId: number | null;
  replacementReason: string | null;
  daysOfWeek: number[];
  disableAttendance: boolean;
  notes: string | null;
  isImmutable: boolean;
  labels: number[];
  recurrenceEndDatetime: Date | null;
}

const convertCalendarEventToScheduleEditEvent = (calendarEvent: CalendarEvent): ScheduleEditEvent => {
  return {
    calendarStartDatetime: new Date(calendarEvent.scheduledStartDatetime),
    assignmentId: calendarEvent.assignmentId,
    calendarDuration: calendarEvent.duration,
    locationId: calendarEvent.locationId,
    supervisorId: calendarEvent.supervisorId,
    employeeId: calendarEvent.assignedUserId,
    previousEmployeeId: calendarEvent.scheduleInfo?.previousAssignedUserId || null,
    replacementReason: calendarEvent.scheduleInfo?.replacementReason || null,
    daysOfWeek: calendarEvent.scheduleInfo?.daysOfWeek?.sort() || [],
    disableAttendance: calendarEvent.scheduleInfo?.disableAttendance || false,
    notes: calendarEvent.scheduleInfo?.notes || null,
    isImmutable: calendarEvent.scheduleInfo?.isImmutable || false,
    labels: calendarEvent.labels || [],
    recurrenceEndDatetime: calendarEvent.scheduleInfo?.recurrenceEndDatetime || null,
  }
}

const ScheduleEditModal = ({ isOpen, calendarEvent, locations, users, labelsMap, close }) => {
  const timezoneContext = useTimezone();
  if (!isOpen) {
    return null;
  }
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const computeInitialCalendarEndDatetime = (event: ScheduleEditEvent) => {
    // calendarStartDatetime is null for new event creation.
    // Setting initial value as null for CalendarEndDatetime as well to not show anything in the UI.
    if (!event.calendarStartDatetime) {
      return null;
    }
    let calendarEndDatetime = addSeconds(event.calendarStartDatetime, event.calendarDuration);
    // we maintain the invariant that calendarStartDatetime and calendarEndDatetime are always on the same day.
    // Using addDays instead of -86400 here to handle the edge case from DST.
    if (!timezoneContext.isSameDay(calendarEndDatetime, event.calendarStartDatetime)) {
      calendarEndDatetime = addDays(calendarEndDatetime, -1);
    }
    return calendarEndDatetime;
  }

  const authContext = useAuth();
  const toast = useToast();
  const developerFeatureEnabled = authContext.state?.features?.developerFeature;

  const isEdit = calendarEvent !== null;
  const originalEvent : ScheduleEditEvent = calendarEvent ? convertCalendarEventToScheduleEditEvent(calendarEvent) :
  {
    calendarStartDatetime: null,
    calendarDuration: 0,
    assignmentId: null,
    previousEmployeeId: null,
    employeeId: null,
    replacementReason: null,
    daysOfWeek: [],
    locationId: null,
    supervisorId: null,
    disableAttendance: false,
    notes: null,
    isImmutable: false,
    labels: [],
  }
  // applyFromDate is the event date for edit, or today for create
  const defaultApplyFromDate = timezoneContext.startOfDay(originalEvent.calendarStartDatetime || new Date());
  const dateForTimePicker = originalEvent.calendarStartDatetime || new Date();
  const [updatedEvent, setUpdatedEvent] = useState(originalEvent);
  const [calendarEndDatetime, setCalendarEndDatetime] = useState<Date | null>(computeInitialCalendarEndDatetime(updatedEvent));
  const [confirmationModalType, setConfirmationModalType] = useState<ConfirmationModalType | null>(null);

  const modalTitle = isEdit ? "Edit schedule" : "Create new schedule";

  let calendarDuration = 0
  if (updatedEvent.calendarStartDatetime && calendarEndDatetime) {
    // Using addDays instead of +86400 here to handle the edge case from DST.
    const actualEndTime = calendarEndDatetime.getTime() < updatedEvent.calendarStartDatetime.getTime() ? addDays(calendarEndDatetime, 1) : calendarEndDatetime;
    calendarDuration = (actualEndTime.getTime() - updatedEvent.calendarStartDatetime.getTime()) / 1000
  }

  const updatedEventWithCalendarDuration = {
    ...updatedEvent,
    calendarDuration: calendarDuration,
  }

  const getNextDayText = () => {
    if (!updatedEvent.calendarStartDatetime || !calendarEndDatetime) {
      return null;
    }
    if (calendarEndDatetime.getTime() >= updatedEvent.calendarStartDatetime.getTime()) {
      return null;
    }
    return "next day";
  }

  const handleFetchError = (error) => {
    toast({
      description: error.message,
      position: "bottom",
      status: "error",
      variant: "left-accent",
      duration: 5000,
      isClosable: true,
    });
  }

  const deleteAssignmentAndClose = () => {
    authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/assignments/${originalEvent.assignmentId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      setConfirmationModalType(null);
      close(true);
    }).catch(handleFetchError)
  }

  const hasChanges = () => {
    return JSON.stringify(originalEvent) !== JSON.stringify(updatedEventWithCalendarDuration)
  }

  const getValidationResult = () => {
    if (!updatedEvent.locationId) {
      return "Please select a building.";
    }
    if (!updatedEvent.supervisorId) {
      return "Please select a supervisor.";
    }
    if (updatedEvent.daysOfWeek.length === 0) {
      return "Please select at least one day.";
    }
    if (!updatedEvent.calendarStartDatetime) {
      return "Please select a start time.";
    }
    if (!calendarEndDatetime) {
      return "Please select an end time.";
    }
    if(calendarDuration <= 0) {
      return "The end time must be after the start time.";
    }
    return null;
  }

  const validateInput = () => {
    toast.closeAll()
    const validationError = getValidationResult();
    if (validationError) {
      toast({
        description: validationError,
        position: "bottom",
        status: "error",
        variant: "left-accent",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }
    return true;
  }

  const saveEventAndClose = (applyFromDate: Date, applyToDate: Date | null, deleteEventsInRange: boolean) => {
    if (!validateInput()) {
      return;
    }

    const url = isEdit ? `${import.meta.env.VITE_API_SERVER}/assignments/${originalEvent.assignmentId}` : `${import.meta.env.VITE_API_SERVER}/assignments`;
    authContext.authenticatedFetch(url, {
      method: isEdit ? "put" : "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event: {
          ...updatedEventWithCalendarDuration,
          calendarStartDatetime: formatISO(updatedEvent.calendarStartDatetime, { representation: 'complete' }),
        },
        // applyToDate and applyFromDate should both be midnight local time
        applyFromDate: formatISO(applyFromDate, { representation: 'complete' }),
        applyToDate: applyToDate ? formatISO(applyToDate, { representation: 'complete'}) : null,
        deleteEventsInRange,
      }),
    }).then(( response: Response ) => {
      return response.json();
    }).then(() => {
      setConfirmationModalType(null);
      close(true);
    }).catch(handleFetchError);
  }

  const renderDebugInfo = () => {
    if (!developerFeatureEnabled) {
      return null;
    }
    return (
      <>
        <Divider my={6}/>
        <FormControl
          className={styles.fieldContainer}
        >
          <FormLabel className={styles.fieldLabel}>Debug info</FormLabel>
          <Textarea
            width={"100%"}
            height={"200"}
            className={styles.fieldValue}
            value={JSON.stringify(calendarEvent, null, 2)}
            resize="none"
            readOnly />
        </FormControl>
      </>
    );
  }

  const renderTopSection = () => {
    const location = locations[updatedEvent.locationId];
    const locationOptions = Object.values(locations)
      .sort((a, b) => a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }))
      .map((location) => ({ label: location.name, value: location.id }));

    return (
      <>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Building</FormLabel>
          {isEdit ? (
            <Input readOnly variant="unstyled" className={styles.fieldValue} value={location?.name} />
          ) : (
            <Select
              options={locationOptions}
              placeholder="Select building"
              onChange={(selectedOption) =>
                setUpdatedEvent({ ...updatedEvent, locationId: selectedOption?.value || null })
              }
              value={locationOptions.find((option) => option.value === updatedEvent.locationId) || null}
            />
          )}
        </FormControl>
        <Flex className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Address</FormLabel>
          <Text className={styles.fieldValue}>{location?.address || "N/A"}</Text>
        </Flex>
      </>
    );
  };

  const renderButtons = () => {
    if (isEdit) {
      return (
        <ButtonGroup
          justifyContent={"space-between"}
          width={"100%"}
          spacing={4}
          mb={2}
        >
          <Button
            flex={1}
            variant='solid'
            colorScheme='gray'
            textColor='red.500'
            onClick={() => setConfirmationModalType(ConfirmationModalType.Delete)}>
              Delete schedule
          </Button>
          <Button
            flex={1}
            isDisabled={!hasChanges() || originalEvent.isImmutable}
            variant='solid'
            onClick={() => {
              if(validateInput()) {
                setConfirmationModalType(ConfirmationModalType.Edit)
              }
            }}>
            {originalEvent.isImmutable? 'Not editable' : 'Save changes'}
          </Button>
        </ButtonGroup>
      )
    }
    return (
      <ButtonGroup
        justifyContent={"space-between"}
        width={"100%"}
        spacing={4}
        mb={2}
      >
        <Button
          flex={1}
          variant='solid'
          isDisabled={!hasChanges()}
          onClick={() => {
            if(validateInput()) {
              setConfirmationModalType(ConfirmationModalType.Create)
            }
          }}>
            Create schedule
        </Button>
      </ButtonGroup>
    );
  }

  const updateEmployeeId = (newEmployeeId: number | null) => {
    let previousEmployeeId = originalEvent.previousEmployeeId;
    if (originalEvent.employeeId !== null // this excludes the case where the original event is unfilled
      && newEmployeeId !== originalEvent.employeeId) {
      previousEmployeeId = originalEvent.employeeId;
    }
    if (newEmployeeId === previousEmployeeId) {
      previousEmployeeId = null;
    }
    setUpdatedEvent({
      ...updatedEvent,
      employeeId: newEmployeeId,
      previousEmployeeId: previousEmployeeId,
      replacementReason: previousEmployeeId ? updatedEvent.replacementReason : null,
    })
  }

  const renderPreviousCleaner = () => {
    if (!isEdit || !updatedEvent.previousEmployeeId) {
      return null;
    }

    const replacementReasonOptions = [
      { label: "No longer employed", value: "NO_LONGER_EMPLOYED" },
      { label: "Reassigned to another location", value: "REASSIGNED" },
      { label: "Vacation", value: "VACATION" },
      { label: "Public holiday", value: "PUBLIC_HOLIDAY" },
      { label: "Planned sick leave", value: "PLANNED_SICK_LEAVE" },
      { label: "Unplanned sick leave", value: "UNPLANNED_SICK_LEAVE" },
      { label: "Family emergency", value: "FAMILY_EMERGENCY" },
      { label: "Other", value: "OTHER" },
    ];

    return (
      <>
        <Divider my={6} />
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Previous cleaner</FormLabel>
          <Input
            width={240}
            className={styles.fieldValue}
            readOnly
            variant="unstyled"
            value={renderUserName(users, updatedEvent.previousEmployeeId, "N/A")}
          />
        </FormControl>
        <FormControl className={styles.fieldContainer}>
          <FormLabel className={styles.fieldLabel}>Replacement reason</FormLabel>
          <Select
            options={replacementReasonOptions}
            placeholder="Select replacement reason"
            onChange={(selectedOption) =>
              setUpdatedEvent({ ...updatedEvent, replacementReason: selectedOption?.value || null })
            }
            value={
              replacementReasonOptions.find((option) => option.value === updatedEvent.replacementReason) || null
            }
          />
        </FormControl>
      </>
    );
  }

  const supervisorOptions = Object.values(users)
    .filter((user) => user.isSupervisor)
    .sort((a, b) =>
      renderUserName(users, a.id).localeCompare(renderUserName(users, b.id), 'en', { sensitivity: 'base' })
    )
    .map((user) => ({ label: renderUserName(users, user.id), value: user.id }));
  const cleanerOptions = Object.values(users)
    .sort((a, b) =>
      renderUserName(users, a.id).localeCompare(renderUserName(users, b.id), 'en', { sensitivity: 'base' })
    )
    .map((user) => ({ label: renderUserName(users, user.id), value: user.id }));

  return (
    <>
      <Modal isOpen={isOpen} onClose={close} size={"xl"} isCentered>
        <ModalOverlay/>
        <ModalContent maxH="90vh">
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
           overflowY="auto"
          >
            {renderTopSection()}

            <Divider my={6}/>
            <FormControl className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Supervisor</FormLabel>
              <Select
                options={supervisorOptions}
                placeholder="Select supervisor"
                onChange={(selectedOption) =>
                  setUpdatedEvent({ ...updatedEvent, supervisorId: selectedOption?.value || null })
                }
                value={supervisorOptions.find((option) => option.value === updatedEvent.supervisorId) || null}
              />
            </FormControl>
            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Cleaner</FormLabel>
              <Select
                options={cleanerOptions}
                placeholder="Select cleaner"
                onChange={(selectedOption) => updateEmployeeId(selectedOption?.value || null)}
                value={cleanerOptions.find((option) => option.value === updatedEvent.employeeId) || null}
              />
            </Flex>
            <Flex className={styles.fieldContainer} pt={2}>
             <Checkbox isChecked={!updatedEvent.employeeId} onChange={
                (event) => updateEmployeeId(event.target.checked ? null : updatedEvent.previousEmployeeId)
              }>
                <Text>Unfilled</Text>
              </Checkbox>
            </Flex>
            <Flex className={styles.fieldContainer} pt={2}>
              <Checkbox isChecked={!updatedEvent.disableAttendance} onChange={
                (event) => setUpdatedEvent({...updatedEvent, disableAttendance: !event.target.checked })
              }>
                <Text>Requires clock-in/out</Text>
              </Checkbox>
            </Flex>
            {renderPreviousCleaner()}
            <Divider my={6}/>

            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Repeat on</FormLabel>
              <ButtonGroup variant="outline" spacing="0" isAttached display={"flex"}>
                {days.map((day, index) => {
                  const sundayBasedIndex = (index + 1) % 7;
                  const isSelected = updatedEvent.daysOfWeek.includes(sundayBasedIndex);

                  return (
                    <Button
                      key={sundayBasedIndex}
                      flex={1}
                      variant={isSelected ? "solid" : "outline"}
                      colorScheme={isSelected ? "primary" : "gray"}
                      fontWeight={"normal"}
                      onClick={() => {
                        if (isSelected) {
                          setUpdatedEvent({
                            ...updatedEvent,
                            daysOfWeek: updatedEvent.daysOfWeek.filter((day) => day !== sundayBasedIndex)
                          });
                        } else {
                          setUpdatedEvent({
                            ...updatedEvent,
                            daysOfWeek: [...updatedEvent.daysOfWeek, sundayBasedIndex].sort()
                          });
                        }
                      }}
                    >
                      {day}
                    </Button>
                  )
                })}
              </ButtonGroup>
            </Flex>

            <Flex className={styles.fieldContainer}>
              <FormLabel className={styles.fieldLabel}>Time</FormLabel>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box width={28}>
                  <DateTimePicker
                    value={updatedEvent.calendarStartDatetime}
                    onChange={(date) => date && setUpdatedEvent({...updatedEvent, calendarStartDatetime: new Date(date.setSeconds(0, 0))})}
                    date={dateForTimePicker}
                    showTime={true}
                    showDate={false}
                  />
                </Box>
                <Box flex={1} borderBottom="1px solid #D9DEE0" ml={2} />
                {!formatDuration(calendarDuration) ? null :
                  <Text
                    px={2}>
                    {formatDuration(calendarDuration)}
                  </Text>
                }
                <Box flex={1} borderBottom="1px solid #D9DEE0" mr={2} />
                <Box width={28}>
                  <DateTimePicker
                    value={calendarEndDatetime}
                    onChange={(date) => date && setCalendarEndDatetime(new Date(date.setSeconds(0, 0)))}
                    date={dateForTimePicker}
                    showTime={true}
                    showDate={false}
                  />
                </Box>
              </Flex>
              <Flex h={6} alignItems="center" justifyContent="flex-end" mr={"32px"} mt={1}>
              {!getNextDayText() ? null :
                <>
                  <HiOutlineExclamationCircle color="#6362F8" size={14}/>
                  <Text alignSelf="center" textAlign={"center"} fontSize={12} ml={1}>
                    {getNextDayText()}
                  </Text>
                </>}
              </Flex>
            </Flex>
            <Divider my={6}/>
             <FormControl
                className={styles.fieldContainer}
              >
                <FormLabel className={styles.fieldLabel}>Notes</FormLabel>
                <Textarea
                  width={"100%"}
                  className={styles.fieldValue}
                  value={updatedEvent.notes || ""}
                  resize="none"
                  onChange={(event) => setUpdatedEvent({...updatedEvent, notes: event.target.value})}
                />
              </FormControl>
              <CalendarEventLabels
              labels={updatedEvent.labels}
              updateLabels={(labels: number[]) => {
                setUpdatedEvent({...updatedEvent, labels: labels})
              }}
              labelsMap={labelsMap}
            />
            {renderDebugInfo()}
          </ModalBody>

          <ModalFooter>
            {renderButtons()}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <ConfirmationModal
        isOpen={confirmationModalType !== null}
        confirmationModalType={confirmationModalType}
        onClose={() => setConfirmationModalType(null)}
        onConfirm={(applyFromDate, applyToDate) => {
          if (confirmationModalType === ConfirmationModalType.Delete) {
            if (!applyFromDate) {
              deleteAssignmentAndClose();
              return;
            }
            saveEventAndClose(applyFromDate, applyToDate, true);
          } else if (confirmationModalType === ConfirmationModalType.Edit) {
            saveEventAndClose(applyFromDate, applyToDate, false);
          } else if (confirmationModalType === ConfirmationModalType.Create) {
            saveEventAndClose(applyFromDate, applyToDate, false);
          }
        }}
        minApplyToDate={addDays(defaultApplyFromDate, 1)}
        defaultApplyToDate={addDays(defaultApplyFromDate, 1)}
        defaultApplyFromDate={defaultApplyFromDate}
        originalEvent={originalEvent}
        updatedEvent={updatedEventWithCalendarDuration}
        users={users}
        locations={locations}
      />
    </>
  );
};

export default ScheduleEditModal;
