export const getShiftByAssignmentId = async (authContext, event) => {
  try {
    const response = await authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/shifts/get_or_create?&assignment_id=${event.assignmentId}&calendar_start_datetime=${event.scheduledStartDatetime}&assignedUserId=${event.assignedUserId}`, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    return result.shift;
  } catch (error) {
    alert(error);
  }
}

export const openShiftPage = async (authContext, event) => {
  if (event?.shifts[0]?.shiftId) {
    window.open(`/shift/${event.shifts[0].shiftId}`, "_blank");
    return;
  }
  const shift = await getShiftByAssignmentId(authContext, event);
  if (!shift?.id) return;
  window.open(`/shift/${shift.id}`, "_blank");
}