import { useAuth } from "@contexts/auth_context";
import { useTheme, Flex, Text, Spinner, Image } from "@chakra-ui/react";
import { useTimezone } from "@contexts/timezone_context";
import useSWR from 'swr';
import { ErrorView } from "@/components/ErrorView";
import calendarIcon from '../../assets/calendar_gray.png';
import { toFixedWithoutZero } from "@/utils/labor_hour_utils";
import { TimePeriod, getTimePeriodText } from "@/views/RealTimeLaborDashboard/real_time_labor_dashboard";
const OvertimeCard = ({ period }: { period: TimePeriod }) => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();

  const { data, error } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/overtime/daily_breakdown?period=${period}`,
    async (url: string) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const isLoading = !data && !error;
  const incurredOvertimeHours = data?.totalIncurredOvertimeHours ?? 0;
  const projectedOvertimeHours = data?.totalProjectedOvertimeHours ?? 0;
  const startDate = data?.startDate ? timezoneContext.formatDate(timezoneContext.parse(data.startDate), 'MMM dd') : null;
  const endDate = data?.endDate ? timezoneContext.formatDate(timezoneContext.parse(data.endDate), 'MMM dd') : null;

  if (error) return <ErrorView message="Failed to load overtime data" />;
  if (isLoading) return <Flex justifyContent="center" alignItems="center" h="100%"><Spinner size="xl" color="purple.500" /></Flex>;

  return (
    <Flex direction="row" align="center" justify="space-between" w="100%" h="100%">
      <Flex direction="column" gap={1} justifyContent="space-between" h="100%" py={6} px={6}>
        <Text fontSize="xl" fontWeight="500">
          Overtime {getTimePeriodText(period)}
        </Text>
        <Flex direction="column" gap={1}>
          <Flex alignItems="center">
            <Image src={calendarIcon} alt="Calendar" mr={2} w={4} h={4} />
            <Text fontSize="md" fontWeight="600" color={theme.colors.secondary.darkGray}>
              {period === TimePeriod.LAST_DAY ? startDate : `${startDate} - ${endDate}`}
            </Text>
          </Flex>
          {projectedOvertimeHours > 0 && (
            <Text fontSize="md">
              <Text as="span" color={theme.colors.secondary.red} fontWeight="bold">
                {toFixedWithoutZero(projectedOvertimeHours)} hours potential overtime by end of {period === 'LAST_DAY' ? 'today' : 'week'}
              </Text>
            </Text>
          )}
        </Flex>
      </Flex>
      <Flex
        bg={incurredOvertimeHours > 0 ? 'rgba(239, 102, 102, 0.08)' : 'rgba(79, 201, 128, 0.08)'}
        borderRadius="3xl"
        h="100%"
        minW={40}
        p={8}
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
      >
        <Text fontSize="4xl" fontWeight="bold" color={incurredOvertimeHours > 0 ? theme.colors.secondary.red : theme.colors.secondary.green} mb={-2}>
          {toFixedWithoutZero(incurredOvertimeHours)}
        </Text>
        <Text fontSize="md" color={incurredOvertimeHours > 0 ? theme.colors.secondary.red : theme.colors.secondary.green}>
          hours
        </Text>
      </Flex>
    </Flex>
  );
};

export default OvertimeCard;
