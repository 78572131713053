import { BiBuildings, BiCalendar, BiEnvelope, BiHomeAlt, BiLogOut, BiTimeFive, BiClipboard, BiCalendarCheck, BiError, BiUser, BiFile, BiShow, BiMenu } from 'react-icons/bi';
import { TbClockDollar } from 'react-icons/tb';
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { Button, Flex, Grid, GridItem, Image, Text, VStack, Box, Icon, useDisclosure } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';

import { CgCalculator } from 'react-icons/cg';
import { FaBroom } from 'react-icons/fa';
import { MdOutlineSpaceDashboard } from 'react-icons/md'
import styles from "./sidebar.module.scss";
import { useAuth } from "../../contexts/auth_context";
import { DEPRECATED_STAGES, IssueStage, OUTDATED_STAGE_LIFECYCLE, StageTagMappings } from "@/types/issue";

const NavButton = ({ text, icon, onClick, isActive, isOpen }) => {
  return (
    <Button
      size='md'
      height='32px'
      width={isOpen ? '230px' : '50px'}
      fontWeight='normal'
      color={isActive ? "primary.500" : "secondary.500"}
      _hover={{ color: "primary.500" }}
      isActive={isActive}
      variant='ghost'
      justifyContent="flex-start"
      onClick={onClick}
      leftIcon={icon}
    >
      {isOpen && text}
    </Button>)
}

const UrlNavButton = ({ text, icon, url, isOpen }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePage = (pagePath: string) => {
    if(pagePath === '/') {
      return location.pathname === '/';
    }
    return location.pathname.includes(pagePath);
  };

  return  <NavButton
    text={text}
    icon={icon}
    onClick={() => {navigate(url)}}
    isActive={isActivePage(url)}
    isOpen={isOpen}
  />
}

// TODO 2023-03 - move the grid layout into a separate component
const Sidebar = () => {
  const authContext = useAuth();

  const stageOptions = authContext.state?.features?.newIssueTrackingWorkflow ? Object.values(IssueStage).filter(stage => !DEPRECATED_STAGES.includes(stage)) : OUTDATED_STAGE_LIFECYCLE;
  const stages = stageOptions.filter(stage => stage !== IssueStage.CLOSED).map((stage) => StageTagMappings[stage].label)

  const VersionInfo = () => {
    return (
      <Flex flexDirection="column" ml={10} fontSize={10} color={"gray.500"}>
        <Text>
          {authContext?.state?.userToken?.organization?.name}
        </Text>
        <Text>
          Version {__COMMIT_HASH__}
        </Text>
      </Flex>
    )
  };

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const DemoFeatures = () => {

    if (!!authContext.state.userToken && authContext.state.userToken.user_info.username.includes("brightgo.com")) {
      return (
        <>
          <UrlNavButton text={"Executive Summary"} icon={<MdOutlineSpaceDashboard/>} url={"/executive_dashboard"} isOpen={isOpen} />
          <UrlNavButton text={"Operations Breakdown"} icon={<BiBuildings/>} url={"/operations_dashboard"} isOpen={isOpen} />
          <UrlNavButton text={"Accounting"} icon={<CgCalculator/>} url={"/accounting_dashboard"} isOpen={isOpen} />
          <UrlNavButton text={"Tag / Ancillary"} icon={<FaBroom/>} url={"/tag_work_dashboard"} isOpen={isOpen} />
        </>
      );
    }

    return null;
  }

  const showMenuToggle = useMediaQuery({ query: '(max-width: 1000px)' });
  return (
    <>
      <Grid
        templateAreas={`"nav main"`}
        gridTemplateRows={'100vh'}
        gridTemplateColumns={isOpen ? '280px 1fr' : '80px 1fr'}
        backgroundColor='secondary.lightGray'
      >
        <GridItem bg='white' area={'nav'} boxShadow='1px 0px 1px rgba(0, 0, 0, 0.05)'>
          <Flex className={styles.sidebar} direction={"column"}>
            <VStack
              spacing={4}
              align='stretch'
            >
              <div className={styles.logoWrapper}>
                <Image
                  width="100px"
                  src={import.meta.env.BASE_URL + "image_65-removebg-preview.png"}
                  mb={6}
                  ml={4}
                  mt={4}
                />
              </div>
              {showMenuToggle &&
                <NavButton text="" icon={<BiMenu />} onClick={onToggle} isActive={false} isOpen={isOpen} />}
              <UrlNavButton text={"Home"} icon={<BiHomeAlt/>} url={"/"} isOpen={isOpen} />
              {authContext.state?.features?.operationsBi && (
                <UrlNavButton text={"Buildings"} icon={<BiBuildings />} url={"/buildings"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.messaging && (
                <UrlNavButton text={"Messaging"} icon={<BiEnvelope />} url={"/messaging"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.inspection && (
                <UrlNavButton text={"Inspection"} icon={<BiClipboard />} url={"/inspection"} isOpen={isOpen} />
              )}
              <UrlNavButton text={"Schedules"} icon={<BiCalendar />} url={"/schedules"} isOpen={isOpen} />
              <UrlNavButton text={"Attendance Review"} icon={<BiTimeFive />} url={"/clock_in_dashboard"} isOpen={isOpen} />
              {authContext.state?.features?.timesheetReviewEnabled && (
                <UrlNavButton text={"Timesheets"} icon={<BiFile />} url={"/timesheets?pay_period=custom"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.attendanceTask && (
                <UrlNavButton text={"Task History"} icon={<BiCalendarCheck />} url={"/task_history"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.issueTrackingSupervisorView && (
                <UrlNavButton text="Issue Tracking" icon={<BiError />} url={`/issues?Stage=${encodeURIComponent(stages.join("|"))}`} isOpen={isOpen} />
              )}
              {authContext.state?.features?.userManagement && (
                <UrlNavButton text="Users" icon={<BiUser />} url={"/users"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.reportsExport && (
                <UrlNavButton text="Reports" icon={<HiOutlineDocumentReport />} url={"/reports"} isOpen={isOpen} />
              )}
              {authContext.state?.features?.realTimeLaborEnabled && (
                <UrlNavButton text="Overtime & Budgets" icon={<TbClockDollar />} url={"/hours"} isOpen={isOpen} />
              )}
            </VStack>

            <div className={styles.signOutButton}>
              <NavButton
                text={"Sign Out"}
                icon={<BiLogOut/>}
                onClick={() => {
                  authContext.signOut(authContext.state, authContext.dispatch);
                }}
                isActive={false} // Sign Out button is never active
                isOpen={isOpen} 
              />
            </div>

            <VersionInfo />

          </Flex>
        </GridItem>
        <GridItem area={'main'} className={styles.example}>
          <div className={styles.mainContainerWrapper}>
            <Outlet />
          </div>
        </GridItem>
      </Grid>
    </>
  )
};

export default Sidebar;
